import React from 'react';
import './StockList.css';

const StockList = React.memo(({ stocks, onSelectStock, selectedStock, isWeekend, isDatabaseUpdating }) => {
  return (
    <div className="stock-list">
      <h2>S&P 500 Stocks</h2>
      {isWeekend && (
        <div className="market-status-banner">
          Market is currently closed. Showing predictions for next Monday.
        </div>
      )}
      {isDatabaseUpdating ? (
        <div className="database-updating-message">
          Database is being updated. This may take a few minutes. Please wait.
        </div>
      ) : stocks.length > 0 ? (
        <ul>
          {stocks.map((stock) => (
            <li 
              key={stock}
              onClick={() => onSelectStock(stock)}
              className={stock === selectedStock ? 'selected' : ''}
            >
              {stock}
            </li>
          ))}
        </ul>
      ) : (
        <p>No stocks available</p>
      )}
    </div>
  );
});

export default StockList;