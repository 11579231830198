import React from "react";
import "./Disclaimer.css";

function Disclaimer({ onAccept, onDecline }) {
  return (
    <div className="disclaimer-overlay">
      <div className="disclaimer-modal">
        <h2>Terms of Consent</h2>
        <p>
          I am not a financial advisor, and the data provided on this
          site is for informational purposes only. The predictions made here are
          generated using machine learning models and should not be considered
          financial advice.
        </p>
        <p>
          You understand and accept that the predictions may not be accurate,
          and any decisions you make based on these predictions are your sole
          responsibility.
        </p>
        <p>
          You should conduct your own research or consult with a professional
          financial advisor before making any investment decisions.
        </p>
        <p>
          The predictions are made before the market opens and do not take into
          consideration market news.
        </p>
        <p>Do you understand and accept these terms?</p>
        <div className="disclaimer-buttons">
          <button onClick={onAccept}>Yes, I Understand and Accept</button>
          <button onClick={onDecline}>No, I Do Not Accept</button>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
