import React, { useState, useEffect, useCallback } from "react";
import SEOMetaTags from "./components/SEOMetaTags";
import SearchBar from "./components/SearchBar/SearchBar";
import StockList from "./components/StockList/StockList";
import StockForecast from "./components/StockForecast/StockForecast";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import "./App.css";

function App() {
  const [stocks, setStocks] = useState([]);
  const [sortedStocks, setSortedStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("symbol");
  const [sortOrder, setSortOrder] = useState("asc");
  const [disclaimerStatus, setDisclaimerStatus] = useState("pending");
  const [isWeekend, setIsWeekend] = useState(false);
  const [isDatabaseUpdating, setIsDatabaseUpdating] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(false);
  const [stockData, setStockData] = useState({});

  const API_BASE_URL = "https://stockforecasts.pro";

  const fetchStocks = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setIsDatabaseUpdating(false);
    try {
      const response = await fetch(`${API_BASE_URL}/api/stocks/sp500?sort_by=${sortBy}&order=${sortOrder}`);
      if (!response.ok) {
        if (response.status === 202) {
          setIsDatabaseUpdating(true);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data && Array.isArray(data.stocks)) {
        setStocks(data.stocks);
        setSortedStocks(data.stocks);
        if (data.stocks.length > 0 && !selectedStock) {
          setSelectedStock(data.stocks[0]);
        }
      } else {
        throw new Error("Invalid data format received from server");
      }
    } catch (error) {
      console.error(`Error fetching stocks:`, error);
      if (error.message === "Failed to fetch") {
        setIsDatabaseUpdating(true);
      } else {
        setError(`Error fetching stocks: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [sortBy, sortOrder, selectedStock]);

  const handleSort = useCallback((newSortBy) => {
    setSortBy(newSortBy);
    setSortOrder((prevOrder) =>
      newSortBy === sortBy && prevOrder === "asc" ? "desc" : "asc"
    );
  }, [sortBy]);

  useEffect(() => {
    if (disclaimerStatus === "accepted") {
      fetchStocks();
    }
  }, [disclaimerStatus, fetchStocks]);

  useEffect(() => {
    const sortStocks = () => {
      const sorted = [...stocks].sort((a, b) => {
        const dataA = stockData[a] || {};
        const dataB = stockData[b] || {};
        
        switch (sortBy) {
          case "symbol":
            return sortOrder === "asc"
              ? a.localeCompare(b)
              : b.localeCompare(a);
          case "difference":
            const diffA = dataA.difference || 0;
            const diffB = dataB.difference || 0;
            return sortOrder === "asc" ? diffA - diffB : diffB - diffA;
          case "volume":
            const volA = dataA.volume || 0;
            const volB = dataB.volume || 0;
            return sortOrder === "asc" ? volA - volB : volB - volA;
          case "close":
            const closeA = dataA.close || 0;
            const closeB = dataB.close || 0;
            return sortOrder === "asc" ? closeA - closeB : closeB - closeA;
          default:
            return 0;
        }
      });
      setSortedStocks(sorted);
    };

    sortStocks();
  }, [stocks, sortBy, sortOrder, stockData]);

  const handleSearch = useCallback((query) => {
    if (query.trim() === "") {
      setSortedStocks(stocks);
    } else {
      const filteredStocks = stocks.filter((stock) =>
        stock.toLowerCase().includes(query.toLowerCase())
      );
      setSortedStocks(filteredStocks);
    }
  }, [stocks]);

  const handleStockSelect = useCallback((stock) => {
    setSelectedStock(stock);
  }, []);

  const handleDataFetched = useCallback((symbol, data) => {
    setStockData(prev => ({
      ...prev, 
      [symbol]: {
        difference: data.difference !== undefined ? data.difference : null,
        volume: data.volume !== undefined ? data.volume : null,
        close: data.close !== undefined ? data.close : null
      }
    }));
  }, []);

  const checkMarketStatus = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/market_status`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsMarketOpen(!data.isWeekend);
      setIsWeekend(data.isWeekend);
    } catch (error) {
      console.error("Error checking market status:", error);
    }
  }, []);

  useEffect(() => {
    checkMarketStatus();
    const intervalId = setInterval(checkMarketStatus, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [checkMarketStatus]);

  const handleDisclaimerAccept = useCallback(() => {
    setDisclaimerStatus("accepted");
    localStorage.setItem("disclaimerAccepted", "true");
  }, []);

  const handleDisclaimerDecline = useCallback(() => {
    setDisclaimerStatus("declined");
  }, []);

  const handleRetryDisclaimer = useCallback(() => {
    setDisclaimerStatus("pending");
  }, []);

  useEffect(() => {
    const accepted = localStorage.getItem("disclaimerAccepted");
    if (accepted === "true") {
      setDisclaimerStatus("accepted");
    }
  }, []);

  const checkDatabaseStatus = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/check_database`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsDatabaseUpdating(data.status === "needs_update" || data.csv_files_empty);
    } catch (error) {
      console.error("Error checking database status:", error);
    }
  }, []);

  useEffect(() => {
    checkDatabaseStatus();
    const intervalId = setInterval(checkDatabaseStatus, 300000); // Check every 5 minutes
    return () => clearInterval(intervalId);
  }, [checkDatabaseStatus]);

  if (disclaimerStatus === "pending") {
    return (
      <Disclaimer
        onAccept={handleDisclaimerAccept}
        onDecline={handleDisclaimerDecline}
      />
    );
  }

  if (disclaimerStatus === "declined") {
    return (
      <div className="disclaimer-declined">
        <div className="disclaimer-declined-content">
          <h1>Access Denied</h1>
          <p>You have declined the terms of use for this application.</p>
          <p>To use this application, you must accept the terms.</p>
          <div className="disclaimer-declined-buttons">
            <button onClick={handleRetryDisclaimer}>Retry</button>
            <button onClick={() => window.close()}>Close Window</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <SEOMetaTags
        title="Stock Forecasts | Real-time Market Predictions"
        description="Get real-time stock forecasts and market predictions for S&P 500 stocks. Make informed investment decisions with our AI-powered analysis."
        keywords="stock forecast, market prediction, S&P 500, investment, AI analysis"
        canonicalUrl="https://stockforecasts.pro"
      />
      <header>
        <h1>Stock Forecasts</h1>
        <div className="market-status">
          Market Status: {isMarketOpen ? "Open" : "Closed"}
        </div>
      </header>
      <nav>
        <div className="sort-options">
          <button onClick={() => handleSort("symbol")}>
            Sort by Symbol {sortBy === "symbol" ? (sortOrder === "asc" ? "▲" : "▼") : null}
          </button>
          <button onClick={() => handleSort("difference")}>
            Sort by Difference {sortBy === "difference" ? (sortOrder === "asc" ? "▲" : "▼") : null}
          </button>
          <button onClick={() => handleSort("volume")}>
            Sort by Volume {sortBy === "volume" ? (sortOrder === "asc" ? "▲" : "▼") : null}
          </button>
          <button onClick={() => handleSort("close")}>
            Sort by Close Price {sortBy === "close" ? (sortOrder === "asc" ? "▲" : "▼") : null}
          </button>
        </div>
      </nav>
      <main>
        <SearchBar onSearch={handleSearch} />
        <div className="content-wrapper">
          <aside className="stock-list-container">
            {isLoading && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
            {isDatabaseUpdating && <p className="updating">Database is updating. Some data may be incomplete or missing.</p>}
            {sortedStocks.length > 0 ? (
              <StockList
                stocks={sortedStocks}
                onSelectStock={handleStockSelect}
                selectedStock={selectedStock}
                isWeekend={isWeekend}
                isMarketOpen={isMarketOpen}
                isDatabaseUpdating={isDatabaseUpdating}
                stockData={stockData}
              />
            ) : (
              <p>No stocks available</p>
            )}
          </aside>
          <section className="forecast-container">
            {selectedStock && (
              <StockForecast
                stockSymbol={selectedStock}
                isWeekend={isWeekend}
                isMarketOpen={isMarketOpen}
                onDataFetched={handleDataFetched}
              />
            )}
          </section>
        </div>
      </main>
    </div>
  );
}

export default App;