import React, { useState, useCallback } from 'react';
import './SearchBar.css';

const SearchBar = React.memo(({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = useCallback((e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(newQuery);
  }, [onSearch]);

  const handleClear = useCallback(() => {
    setQuery('');
    onSearch('');
  }, [onSearch]);

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search for a stock..."
        value={query}
        onChange={handleInputChange}
      />
      {query && (
        <button className="clear-button" onClick={handleClear}>
          Clear
        </button>
      )}
    </div>
  );
});

export default SearchBar;