import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "./StockForecast.css";

const API_BASE_URL = 'https://stockforecasts.pro';

const formatNumber = (value) => {
  if (value === null || value === undefined || isNaN(value)) return 'N/A';
  return value < 1 ? value.toFixed(4) : value.toFixed(2);
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
};

const calculatePercentageChange = (oldValue, newValue) => {
  if (oldValue === 0 || oldValue === null || newValue === null || isNaN(oldValue) || isNaN(newValue)) return 0;
  return ((newValue - oldValue) / oldValue) * 100;
};

const StockForecast = React.memo(({ stockSymbol, onDataFetched, isWeekend, isMarketOpen }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/historical/${stockSymbol}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        
        if (!result.historical || result.historical.length === 0) {
          throw new Error("No historical data available");
        }
        
        setData(result);
        
        if (result.lastKnownValues && result.prediction && result.prediction.values) {
          const openDifference = calculatePercentageChange(
            result.lastKnownValues.open,
            result.prediction.values.Open
          );
          onDataFetched(stockSymbol, {
            difference: openDifference,
            volume: result.lastKnownValues.volume || null,
            close: result.lastKnownValues.close || null
          });
        } else {
          console.warn(`Incomplete data for ${stockSymbol}`);
          onDataFetched(stockSymbol, {
            difference: null,
            volume: null,
            close: null
          });
        }
      } catch (e) {
        console.error("Error fetching forecast:", e);
        setError(`Error fetching forecast: ${e.message}. Please try again later.`);
        onDataFetched(stockSymbol, {
          difference: null,
          volume: null,
          close: null
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (stockSymbol) {
      fetchData();
    }
  }, [stockSymbol, onDataFetched]);

  if (isLoading) return <div className="stock-forecast">Loading forecast data...</div>;
  if (error) return <div className="stock-forecast error">{error}</div>;
  if (!data || !data.historical || data.historical.length === 0) {
    return <div className="stock-forecast">No historical data available for {stockSymbol}.</div>;
  }

  const { prediction, historical, lastKnownValues, securityName, currentDate } = data;

  const chartData = historical.map((record) => ({
    date: formatDate(record.date),
    Open: parseFloat(record.open) || null,
    High: parseFloat(record.high) || null,
    Low: parseFloat(record.low) || null,
    Close: parseFloat(record.close) || null,
  })).filter(record => 
    record.Open !== null && record.High !== null && record.Low !== null && record.Close !== null
  );
    
  if (prediction && prediction.date && prediction.values) {
    const predictionData = {
      date: formatDate(prediction.date),
      OpenPrediction: parseFloat(prediction.values.Open) || null,
      HighPrediction: parseFloat(prediction.values.High) || null,
      LowPrediction: parseFloat(prediction.values.Low) || null,
      ClosePrediction: parseFloat(prediction.values.Close) || null,
    };
    if (predictionData.OpenPrediction !== null && predictionData.HighPrediction !== null &&
        predictionData.LowPrediction !== null && predictionData.ClosePrediction !== null) {
      chartData.push(predictionData);
    }
  }


  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FinancialProduct",
    name: `${securityName || stockSymbol} Stock`,
    description: `Stock forecast and historical data for ${securityName || stockSymbol}`,
    ticker: stockSymbol,
    price: lastKnownValues ? lastKnownValues.close : null,
    priceCurrency: "USD",
    exchange: "NYSE",
  };

  const getPredictionDateText = () => {
    if (!prediction || !prediction.date) return "No prediction available";
    const predictionDate = new Date(prediction.date);
    const currentDateObj = new Date(currentDate);
    
    if (predictionDate.getTime() === currentDateObj.getTime()) {
      return isWeekend ? "Monday's Prediction" : "Today's Prediction";
    } else {
      return `Prediction for ${formatDate(prediction.date)}`;
    }
  };

  return (
    <article className="stock-forecast">
      <Helmet>
        <title>{`${securityName || stockSymbol} Stock Forecast | Real-time Market Predictions`}</title>
        <meta
          name="description"
          content={`Get real-time stock forecast and market predictions for ${
            securityName || stockSymbol
          }. View historical data and AI-powered analysis for informed investment decisions.`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <h2>{securityName || stockSymbol} Forecast</h2>

      {!isMarketOpen && (
        <div className="market-status-banner">
          Market is currently closed.
          {isWeekend
            ? " Showing prediction for next Monday."
            : " Showing prediction for next market open."}
        </div>
      )}

      <div className="chart-container">
        <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
          <LineChart
            data={chartData}
            margin={
              isMobile
                ? { top: 5, right: 10, left: 0, bottom: 40 }
                : { top: 20, right: 30, left: 20, bottom: 20 }
            }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              angle={-45}
              textAnchor="end"
              height={60}
              tick={{ fontSize: isMobile ? 10 : 12 }}
            />
            <YAxis tick={{ fontSize: isMobile ? 10 : 12 }} />
            <Tooltip />
            {!isMobile && <Legend />}
            <Line type="monotone" dataKey="Open" stroke="#8884d8" dot={false} />
            <Line type="monotone" dataKey="High" stroke="#82ca9d" dot={false} />
            <Line type="monotone" dataKey="Low" stroke="#ffc658" dot={false} />
            <Line type="monotone" dataKey="Close" stroke="#ff7300" dot={false} />
            <Line type="monotone" dataKey="OpenPrediction" stroke="#8884d8" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="HighPrediction" stroke="#82ca9d" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="LowPrediction" stroke="#ffc658" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="ClosePrediction" stroke="#ff7300" strokeDasharray="5 5" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="data-info">
        {lastKnownValues && (
          <section className="last-known-values">
            <h3>Last Known Values ({formatDate(lastKnownValues.date)})</h3>
            <p>Open: <span className="value">${formatNumber(lastKnownValues.open)}</span></p>
            <p>High: <span className="value">${formatNumber(lastKnownValues.high)}</span></p>
            <p>Low: <span className="value">${formatNumber(lastKnownValues.low)}</span></p>
            <p>Close: <span className="value">${formatNumber(lastKnownValues.close)}</span></p>
            <p>Volume: <span className="value">{lastKnownValues.volume ? lastKnownValues.volume.toLocaleString() : 'N/A'}</span></p>
          </section>
        )}

        {prediction && prediction.date && prediction.values && (
          <section className="last-known-values">
            <h3>{getPredictionDateText()} ({formatDate(prediction.date)})</h3>
            <p>Open: <span className="value">${formatNumber(prediction.values.Open)}</span></p>
            <p>High: <span className="value">${formatNumber(prediction.values.High)}</span></p>
            <p>Low: <span className="value">${formatNumber(prediction.values.Low)}</span></p>
            <p>Close: <span className="value">${formatNumber(prediction.values.Close)}</span></p>
          </section>
        )}

        {prediction && prediction.date && prediction.values && lastKnownValues && (
          <section className="last-known-values">
            <h3>Predicted Differences</h3>
            <p>Open: <span className="value">{formatNumber(calculatePercentageChange(lastKnownValues.open, prediction.values.Open))}%</span></p>
            <p>High: <span className="value">{formatNumber(calculatePercentageChange(lastKnownValues.high, prediction.values.High))}%</span></p>
            <p>Low: <span className="value">{formatNumber(calculatePercentageChange(lastKnownValues.low, prediction.values.Low))}%</span></p>
            <p>Close: <span className="value">{formatNumber(calculatePercentageChange(lastKnownValues.close, prediction.values.Close))}%</span></p>
          </section>
        )}
      </div>
    </article>
  );
});

export default StockForecast;